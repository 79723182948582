<template lang="pug">
	section#login
		.wrapper
			div.registre
				h2 Registre
				//- p Lorem ipsum dolor sit amet, consectetur adip incididunt ut labore et dolore magna aliqua. 
				form(@submit.prevent="sendRegistre", autocomplete="off")
					.input(:class="{error: errors && errors.nome }")
						input(type="text", placeholder="Nome", v-model="formRegistre.nome")
						span(v-if="errors && errors.nome").error {{ errors.nome[0] }}
					.input(:class="{error: errors && errors.email }")
						input(type="email", placeholder="E-mail", v-model="formRegistre.email")
						span(v-if="errors && errors.email").error {{ errors.email[0] }}
					button.registrar Registrar
				// span Verifique os campos destacados*
			div.login
				h2 Login
				//- p Lorem ipsum dolor sit amet, consectetur adip incididunt ut labore et dolore magna aliqua. 
				form(v-on:submit.prevent="sendLogin", autocomplete="off")
					.input(:class="{error: $auth.errors && $auth.errors.email }")
						input(placeholder="E-mail", name="email", v-model="$auth.formLogin.email")
						span(v-if="$auth.errors && $auth.errors.email").error {{ $auth.errors.email[0] }}
					.input(:class="{error: $auth.errors && $auth.errors.password }")
						input(type="password", placeholder="Senha", name="senha", v-model="$auth.formLogin.password")
						span(v-if="$auth.errors && $auth.errors.password").error {{ $auth.errors.password[0] }}
					button(:disabled="sending").logar Entrar
				router-link(:to="{name: 'esqueceuSenha' }") Esqueceu a senha?
			.ajuda
				h3 Está com dificuldade no acesso?
				p
					| Caso não consiga ter acesso área do associados ou alguma outra dificuldade, 
					| você pode entrar em contato conosco pelos contatos abaixo. 
				p 
					| E-mail: 
					a(href="mailto:contato@paliativo.org.br") contato@paliativo.org.br
				p
					| Telefone: 
					a(href="tel:+5511931465291") (11) 93146-5291
</template>

<script>
export default {
	data() {
		return {
			redirectTo: '',
			sending: false,
			formRegistre: {
				nome: '',
				email: ''
			},
			errors: null
		}
	},
	mounted() {
		if (this.$route.query.redirectTo)
			this.redirectTo = this.$route.query.redirectTo
	},
	methods: {
		sendLogin() {
			this.sending = true;
			this.$auth.login()
				.then(response => {
					this.sending = false;
					setTimeout(() => {
						if (response)
							this.$router.replace({ path: `/${this.redirectTo}` })
					}, 250)
				});
		},
		sendRegistre() {
			this.errors = null;
			this.$axios
				.post(`api/pre-registro`, this.formRegistre)
				// eslint-disable-next-line
				.then(response => {
					let redirectTo = this.redirectTo
					this.$router.push({
						name: 'registre', 
						query: {
							redirectTo,
							nome: this.formRegistre.nome,
							email: this.formRegistre.email
						}
					})
				})
				.catch(error => {
					if (error.response.status == 422)
						this.errors = error.response.data.errors
				})
		}
	},
}
</script>

<style lang="stylus" scoped src="./Login.styl">
</style>